import "./styles.scss";

import { StyleSheet } from "@react-pdf/renderer";

import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportB,
} from "../../../../../../../components/PDFReport";
import { ReportPageProps } from "../../../../../../../types/Reports";
import { useEffect, useState } from "react";

const styles = StyleSheet.create({
  pageStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    paddingTop: 6,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginBottom: 12
  },
  scopeColumn: {
    display: "flex",
    flexDirection: "column",
    maxWidth: '25%',
    flex: 1
  },
  scopeCell: {
    padding: 4,
    color: '#FBFAF8'
  },
  activityCell: {
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 6
  },
  activityText: {
    fontSize: 9
  },
  scope1Header: {
    backgroundColor: '#0076A1FF'
  },
  scope2Header: {
    backgroundColor: '#83D1B9FF'
  },
  scope3Header: {
    backgroundColor: '#008575FF'
  },
  scope3bHeader: {
    backgroundColor: '#008575',
    color: 'transparent'
  },
  scope1: {
    backgroundColor: '#0076A119'
  },
  scope2: {
    backgroundColor: '#83D1B919'
  },
  scope3: {
    backgroundColor: '#00857519'
  },
  scope3b: {
    backgroundColor: '#00857519'
  }
});

const getScopeStyle = (scope: string, isHeader: boolean = false) => {
  switch(scope) {
    case "1":
      return isHeader ? styles.scope1Header : styles.scope1;
    case "2":
      return isHeader ? styles.scope2Header : styles.scope2;
    case "3":
      return isHeader ? styles.scope3Header : styles.scope3;
    default:
      return isHeader ? styles.scope3bHeader : styles.scope3;
  }
}

export interface ScopeActivities {
  [key: string]: string[];
}

const MAX_ITEMS_COLUMN = 21;

export const OperationalBoundary: React.FC<ReportPageProps> = ({
  page,
  className,
  downloadVersion = false,
  onContentUpdate = () => {},
}) => {
  const [scopeActivities, setScopeActivities] = useState<ScopeActivities>();

  useEffect(() => {
    if(page?.details?.[0].comments?.content?.[0])
    {
      var scopeData = JSON.parse(page?.details?.[0].comments?.content[0]);

      Object.keys(scopeData).forEach(key => scopeData[key].sort(
        (a: string, b: string) => a.localeCompare(b)
      ));
      
      if(scopeData?.["3"]?.length > MAX_ITEMS_COLUMN) {
        var splitArrays = Array.from({ length: Math.ceil(scopeData["3"].length / MAX_ITEMS_COLUMN) }, (v, i) =>
          scopeData["3"].slice(i * MAX_ITEMS_COLUMN, i * MAX_ITEMS_COLUMN + MAX_ITEMS_COLUMN)
        );

        scopeData["3"] = splitArrays[0];
        for (let i = 1; i < splitArrays.length; i++) {
          scopeData[`3-${i}`] = splitArrays[i];
        }
      }

      setScopeActivities(scopeData);
    }
  }, [page])

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`operational-boundary image-full-page ${className}`}
      style={styles.pageStyle}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={false}
      page={page}
      showHeader
    >
      <ReportP downloadVersion={downloadVersion}>
        Throughout the data collection process you provided us data on your operations. Below is the full list of activities captured within this report.
      </ReportP>
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        {scopeActivities && Object.keys(scopeActivities).map((s, index) => (
          <ReportDiv
            key={index}
            className={`scope-column scope-${s}`}
            style={{...styles.scopeColumn, ...(getScopeStyle(s))}}
            downloadVersion={downloadVersion}>
            <ReportDiv
              className={'cell scope-cell'}
              style={{...styles.scopeCell, ...(getScopeStyle(s, true))}}
              downloadVersion={downloadVersion}
            >
              <ReportB downloadVersion={downloadVersion}>Scope {s.length > 1 ? "3 Continued" : s}</ReportB>
            </ReportDiv>
            {scopeActivities[s].map((n, index) => (
              <ReportDiv
                key={index}
                className={'cell activity-cell'}
                style={styles.activityCell}
                downloadVersion={downloadVersion}
              >
                <ReportP downloadVersion={downloadVersion} style={styles.activityText}>{n}</ReportP>
              </ReportDiv>
            ))}
          </ReportDiv>
        ))}
      </ReportDiv>
    </ReportPage>
  );
};
