import './styles.scss';

import { StyleSheet } from '@react-pdf/renderer';

import { BarChart, BarDataType, BartCO2eDifference } from '../BarChart';
import {
  ReportPage,
  ReportDiv,
  ReportChart,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import { getImpactAreaColor } from '../../../../../../helpers/colors';
import mapImpactAreaChartData from '../../../../../../helpers/mapImpactAreaChartData';

const styles = StyleSheet.create({
  carbonFootprintImpactAreaPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 12.5,
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12.5,
  },
  scopeStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
  },
  iconText: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: 18,
  },
  iconContainer: {
    width: 36,
    aspectRatio: 1,
    borderRadius: '100%',
    backgroundColor: '#e35924',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainerTravel: {
    backgroundColor: '#c7d043',
  },
  icon: {
    width: 18,
    aspectRatio: 1,
  },
  iconTextP: {
    width: '100%',
  },
  bottomText: {
    position: 'absolute',
    bottom: -20,
    width: '100%',
    textAlign: 'center',
  },
});

const ImpactAreaOrderKey: { [key: string]: number } = {
  'buildings': 0,
  'people': 1,
  'supply chain': 2,
  'travel & logistics': 3,
  'travel and logistics': 3,
  'travel': 3,
}

export const CarbonFootprintImpactArea: React.FC<ReportPageProps> = ({ page, className, downloadVersion = false, onContentUpdate, editable }) => {
  const { details, charts } = page;
  const chart = {
    ...charts?.[0],
    legend: charts?.[0].legend.sort((a, b) => ImpactAreaOrderKey[b.toLocaleLowerCase()] - ImpactAreaOrderKey[a.toLocaleLowerCase()])
  };

  const data = mapImpactAreaChartData(chart);

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`carbon-footprint-impact-area-page ${className}`}
      style={styles.carbonFootprintImpactAreaPage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="chart-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportChart downloadVersion={downloadVersion}>
            <BarChart
              width={downloadVersion ? 300 : 450}
              height={downloadVersion ? 400 : 550}
              fontSize={downloadVersion ? 10 : 16}
              data={data as BarDataType[]}
              keys={chart.legend}
              barComponent={(props) => (
                <BartCO2eDifference
                  {...props}
                  topBarId={['Buildings']}
                  darkTextContrast={['Travel', 'People', 'Travel & Logistics', 'Travel and Logistics']}
                  downloadVersion={downloadVersion}
                />
              )}
              getColor={getImpactAreaColor}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom',
                  direction: 'row',
                  translateY: downloadVersion ? 40 : 60,
                  itemWidth: downloadVersion ? 50 : 90,
                  itemHeight: downloadVersion ? 15 : 20,
                  itemDirection: 'left-to-right',
                  symbolSize: downloadVersion ? 8 : 15,
                  symbolSpacing: 2,
                  data: [...chart.legend].reverse().map((item) => ({
                    id: item,
                    label: item,
                    color: String(getImpactAreaColor(item)),
                  })),
                },
              ]}
              theme={{
                legends: {
                  text: {
                    fontSize: downloadVersion ? 6 : 10,
                    fill: '#011E26',
                  },
                },
              }}
            />
          </ReportChart>
        </ReportDiv>
        <ReportDiv
          className="items-side"
          style={{ ...styles.side, ...styles.itemSide }}
          downloadVersion={downloadVersion}
        >
        {details.map((detail, index) => {
          const impactAreaName = detail?.comments?.header;
          return (
            <ReportEditor
              key={index}
              id="travel-report-editor"
              className="impact-area-editor"
              contentId={detail.id}
              editorContent={detail.comments}
              downloadVersion={downloadVersion}
              editable={editable}
              preventColorChange
              onChange={() => onContentUpdate()}
              borderColor={getImpactAreaColor(impactAreaName || '')}
            />
          )
        })}
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
