import { BarItemProps, BarLegendProps, ValueFormatter, Bar, BarLayer } from '@nivo/bar';
import { Margin, Theme } from '@nivo/core';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import { fallbackChartColors } from '../../../../../../helpers/colors';
import { FONT_FAMILY } from '../../../../../../styles/font';

export type BarDataType = {
  key: string;
};

type StackChartProps = {
  data: BarDataType[];
  keys?: string[];
  getColor?: (id: string) => string;
  legends?: BarLegendProps[];
  axisLeft?: AxisProps | null;
  axisBottom?: AxisProps;
  barComponent?: React.FC<BarItemProps<any>>;
  width?: number;
  height?: number;
  fontSize?: number;
  groupMode?: 'stacked' | 'grouped';
  layout?: 'vertical' | 'horizontal';
  enableLabel?: boolean;
  enableGridY?: boolean;
  enableGridX?: boolean;
  gridYValues?: number | null;
  gridXValues?: number | null;
  margin?: Partial<Margin> | undefined;
  layers?: BarLayer<any>[] | undefined;
  theme?: Theme;
};

type AxisProps = {
  tickSize?: number;
  tickPadding?: number;
  tickRotation?: number;
  legend?: string;
  legendOffset?: number;
  format?: string | ValueFormatter;
  tickValues?: number;
  legendPosition? : 'start' | 'end' | 'middle';
};

const commonProps = {
  padding: 0.2,
  isInteractive: false,
  enableGridY: false,
  indexBy: 'key',
};

export const BarChart: React.FC<StackChartProps> = ({
  data,
  getColor,
  keys,
  legends,
  axisLeft = null,
  axisBottom,
  barComponent,
  width = 300,
  height = 300,
  fontSize = 16,
  groupMode = 'stacked',
  layout = 'vertical',
  enableLabel = true,
  enableGridY = false,
  enableGridX = false,
  gridXValues = 5,
  gridYValues = 5,
  margin = { top: 90, right: 0, bottom: 60, left: 0 },
  layers,
  theme = {}
}: StackChartProps) => {
  const getKeys = (data: BarDataType[]): string[] => {
    return data.map((dataItem) => dataItem.key);
  };

  return (
    <Bar
      {...commonProps}
      margin={margin}
      width={width}
      height={height}
      data={data}
      keys={keys || getKeys(data)}
      indexBy="key"
      colors={getColor ? ({ id }) => getColor(id as string) : fallbackChartColors}
      axisLeft={axisLeft}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        ...axisBottom,
      }}
      groupMode={groupMode}
      layout={layout}
      barComponent={barComponent}
      legends={legends}
      enableLabel={enableLabel}
      enableGridY={enableGridY}
      enableGridX={enableGridX}
      gridYValues={gridYValues === null ? undefined : gridYValues}
      gridXValues={gridXValues === null ? undefined : gridXValues}
      layers={layers}
      theme={{
        background: '#fbfaf8',
        text: { 
          fontFamily: FONT_FAMILY,
        },
        legends: {
          text: {
            fontSize: fontSize,
            fill: '#011E26',
          },
        },
        axis: {
          ticks: {
            text: {
              fontSize: fontSize,
              fill: '#011E26',
            },
          },
        },
        ...theme
      }}
    />
  );
};

type BartCO2eDifferenceType = {
  key: string;
  tCO2eTotal: number;
  value: number;
  difference: number;
};

interface BartCO2eDifferenceProps extends BarItemProps<BartCO2eDifferenceType> {
  topBarId?: string[];
  darkTextContrast?: string[];
  downloadVersion?: boolean;
  hideValues?: boolean;
  color?: string;
}

export const BartCO2eDifference: React.FC<BartCO2eDifferenceProps> = ({
  bar,
  topBarId = [],
  darkTextContrast = [],
  downloadVersion = false,
  hideValues = false,
  color
}) => {
  return (
    <g transform={`translate(${bar.x},${bar.y})`}>
      <rect width={bar.width} height={bar.height} fill={color ?? bar.color} />
      {bar.height > 15 && !hideValues && (
        <text
          x={bar.width / 2}
          y={bar.height / 2}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 8 : 14,
            fill: darkTextContrast.includes(String(bar.data.id))
              ? '#011E26'
              : '#fbfaf8',
          }}
        >
          {Math.round(
            ((bar.data.value || 0) / (bar.data.data.tCO2eTotal || 0)) * 100
          )}
          %, {numberLocaleFormatter(bar.data.value)} tCO2e
        </text>
      )}
      {topBarId.includes(bar.data.id as string) && (
        <text
          x={bar.width / 2}
          y={-16}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 10 : 16,
            fill: '#011E26',
          }}
        >
          {numberLocaleFormatter(bar.data.data.tCO2eTotal)} tCO2e
        </text>
      )}
      {topBarId.includes(bar.data.id as string) &&
        (bar.data.data.difference || 0) && (
          <>
            <text
              x={bar.width / 2}
              y={-40}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: downloadVersion ? 10 : 16,
                fontWeight: 700,
                fill:
                  (bar.data.data.difference || 0) < 0 ? '#265E33' : '#A02727',
              }}
            >
              {bar.data.data.difference || 0}%
            </text>
            <g
              transform={
                downloadVersion ? 'translate(65, -52)' : 'translate(100, -52)'
              }
              width={downloadVersion ? 10 : 18}
              height={downloadVersion ? 10 : 18}
              fill="none"
              stroke={
                (bar.data.data.difference || 0) < 0 ? '#265E33' : '#A02727'
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {(bar.data.data.difference || 0) < 0 ? (
                <>
                  <path d="M12 16L12 8" />
                  <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" />
                </>
              ) : (
                <>
                  <path d="M12 8L12 16" />
                  <path d="M15 11L12.087 8.08704V8.08704C12.039 8.03897 11.961 8.03897 11.913 8.08704V8.08704L9 11" />
                </>
              )}
            </g>
          </>
        )}
    </g>
  );
};
