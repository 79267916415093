import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "@flotilla/component-library";

import { updateSurvey as updateSurveyAPI, createSurvey, getFullSurveys } from "../../api/surveys";
import useCompany from "../Company/useCompany";
import { useCompanyId } from "../../context";
import { useAppDispatch } from "../../helpers/hooks";
import { Survey } from "../../types/Survey";

const useSurvey = (id?: number) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const companyId = useCompanyId();
  const [company] = useCompany(companyId);
  const [loading, setLoading] = useState(false);
  const [surveyId, setSurveyId] = useState<number | undefined>(id || Number(paramId));
  const [survey, setSurvey] = useState<Survey>();
  const [updated, setUpdated] = useState(false);

  const fetchApi = useCallback(async () => {
    if (surveyId && companyId && company) {
      setLoading(true);
      await getFullSurveys(companyId, company.identityCompanyId)
        .then((res) => {
          setSurvey(res.find((item) => item.id === surveyId));
        })
        .finally(() => setLoading(false));
    }
  }, [surveyId, companyId, company]);

  const refreshSurvey = () => {
    fetchApi();
  }

  const handleError = (err: any) => {
    if(err?.message?.includes("can't overlap")) {
      addAlert({ id: `Survey Create Failed ${surveyId}`, type: "error", title: "Survey Create Unsuccessful", content: "The survey start and end dates cannot overlap with an existing survey." });
    } else if(err?.message?.includes("FromDate must be before")) {
      addAlert({ id: `Survey Create Failed ${surveyId}`, type: "error", title: "Survey Create Unsuccessful", content: "The survey end date must be after the survey start date." });
    } else {
      addAlert({ id: `Survey Create Failed ${surveyId}`, type: "error", title: "Survey Create Unsuccessful", content: "Survey failed to save please try again." });
    }
  }

  const saveSurvey = async (): Promise<Survey | void> => {
    if (surveyId && survey) {
      setLoading(true);
      return await updateSurveyAPI(companyId, company.identityCompanyId.toString(), survey)
        .then(() => {
          addAlert({ id: `Survey Saved ${surveyId}`, type: "success", title: "Survey Saved Successfully" });
          return survey;
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false);
          setUpdated(false);
        });
    } else if (survey) {
      setLoading(true);
      return await createSurvey(companyId, company.identityCompanyId.toString(), survey)
        .then((res) => {
          setSurveyId(Number(res));
          addAlert({ id: `Survey Created ${res}`, type: "success", title: "Survey Created Successfully" });
          return {
            ...survey,
            id: Number(res)
          };
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false);
          setUpdated(false);
        });
    }
  }

  const updateSurvey = (updatedSurvey: Partial<Survey>) => {
    setSurvey({
      ...survey,
      ...updatedSurvey
    } as Survey);
    setUpdated(true);
  }

  useEffect(() => {
    if (surveyId) {
      fetchApi();
    }
  }, [surveyId, dispatch, fetchApi]);

  useEffect(() => {
    setSurveyId(id || Number(paramId));
  }, [id, paramId]);

  return {
    survey,
    refreshSurvey,
    saveSurvey,
    updateSurvey,
    loading,
    updated
  };
}

export default useSurvey;
