import './styles.scss';

import { Pie, PieCustomLayer } from '@nivo/pie';
import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import {
  carbonFootprintBaselineYearColors,
  defaultPieColorScale,
} from '../../../../../../helpers/colors';
import PlaneIcon from '../../images/PlaneIcon.png';
import CarIcon from '../../images/CarIcon.png';
import PersonIcon from '../../images/PersonIcon.png';
import {
  ReportPage,
  ReportDiv,
  ReportP,
  ReportB,
  ReportImage,
  ReportChart,
  ReportEditor
} from '../../../../../../components/PDFReport';
import { ReportPageProps } from '../../../../../../types/Reports';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import { FONT_FAMILY } from '../../../../../../styles/font';

const styles = StyleSheet.create({
  carbonFootprintBaselinePage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 25,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
  },
  side: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 25,
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12.5,
  },
  scopeStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
  },
  iconText: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: 18,
  },
  iconContainer: {
    width: 36,
    aspectRatio: 1,
    borderRadius: '100%',
    backgroundColor: '#e35924',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainerTravel: {
    backgroundColor: '#c7d043',
  },
  icon: {
    width: 18,
    aspectRatio: 1,
  },
  iconTextP: {
    width: '100%',
  },
  scope1: {
    borderColor: '#0076a1',
  },
  scope2: {
    borderColor: '#83d1b9',
  },
  scope3: {
    borderColor: '#008575',
  },
});

type StatProps = {
  id?: string;
  style?: Style;
  label: string;
  value: number | string;
  secondaryValue?: number | string;
  downloadVersion?: boolean;
};

export const CarbonFootprintBaselineYear: React.FC<ReportPageProps> = ({ report, page, className, downloadVersion = false, onContentUpdate, editable }) => {
  const extras = page.pageTitle.includes("baseline") ? report.extras : report.extras.latest;
  const {
    scope1Percent,
    scope1tCO2e,
    scope2Percent,
    scope2tCO2e,
    scope3Percent,
    scope3tCO2e
  } = extras;
  const { details, charts } = page;
  const chartData = charts?.[0].data;

  const getColor = (id: string, index: number) => {
    return carbonFootprintBaselineYearColors[
      id.toLowerCase() as string
    ] || defaultPieColorScale[index % 15]
  };

  const data = chartData.sort((a, b) => b.value - a.value).map((d, index) => ({
    id: d.xLabel,
    label: d.xLabel,
    value: d.value,
    color: getColor(d.xLabel, index)
  }));


  const getDataColor = (id: string) => {
    return data.find((item) => item.id === id)?.color || '#000000';
  }

  const Stat: React.FC<StatProps> = ({
    id,
    style,
    label,
    value,
    secondaryValue,
    downloadVersion = false,
  }) => {
    const statStyles = StyleSheet.create({
      stat: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 6,
        borderLeft: '4px solid #011e26',
        backgroundColor: '#EEEEED',
        borderRadius: 4,
        padding: '8px 12.5px',
      },
      label: {
        width: '100%',
      },
      valuesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 3,
      },
      value: {
        fontSize: 36,
      },
    });
    return (
      <ReportDiv
        className="stat"
        style={{ ...statStyles.stat, ...style }}
        id={id}
        downloadVersion={downloadVersion}
      >
        <ReportP
          className="label"
          style={statStyles.label}
          downloadVersion={downloadVersion}
        >
          {label}
        </ReportP>
        <ReportDiv
          className="values-container"
          style={statStyles.valuesContainer}
          downloadVersion={downloadVersion}
        >
          {downloadVersion ? (
            <>
              <ReportP
                className="value"
                style={statStyles.value}
                downloadVersion={downloadVersion}
              >
                {value}
              </ReportP>
              {secondaryValue && (
                <ReportP
                  className="secondary-value"
                  downloadVersion={downloadVersion}
                >
                  {secondaryValue}
                </ReportP>
              )}
            </>
          ) : (
            <>
              <span className="value">{value}</span>
              {secondaryValue && (
                <span className="secondary-value">{secondaryValue}</span>
              )}
            </>
          )}
        </ReportDiv>
      </ReportDiv>
    );
  };

  const CenteredMetric: PieCustomLayer<{
    id: string;
    label: string;
    value: number;
  }> = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 10}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 40 : '64px',
            fontFamily: FONT_FAMILY,
          }}
        >
          {numberLocaleFormatter(extras.totaltCo2e)}
        </text>
        <text
          x={centerX}
          y={centerY + (downloadVersion ? 25 : 50)}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: downloadVersion ? 10 : 16,
            fontFamily: FONT_FAMILY,
          }}
        >
          Total tCO2e
        </text>
      </>
    );
  };

  return (
    <ReportPage
      id={`${page.pageTitle} - ${page.pageNumber}`}
      className={`carbon-footprint-baseline-year-page ${className}`}
      style={styles.carbonFootprintBaselinePage}
      downloadVersion={downloadVersion}
      footer={page.footer}
      onChange={() => onContentUpdate()}
      pageId={page.pageId}
      showFooter
      editable={editable}
      page={page}
      showHeader
    >
      <ReportDiv
        className="content"
        style={styles.content}
        downloadVersion={downloadVersion}
      >
        <ReportDiv
          className="chart-side"
          style={styles.side}
          downloadVersion={downloadVersion}
        >
          <ReportChart downloadVersion={downloadVersion}>
            <Pie
              data={data}
              margin={{
                top: 0,
                right: 60,
                bottom: downloadVersion ? 200 : 300,
                left: downloadVersion ? 60 : 50,
              }}
              innerRadius={0.6}
              borderColor={{ theme: 'background' }}
              colors={({ data }) => getDataColor(data.id)}
              arcLabelsTextColor={(data) =>
                [
                  'Technology',
                  'Professional fees',
                  'Facilities',
                  'Energy',
                ].includes(String(data.id))
                  ? '#fbfaf8'
                  : '#011e26'
              }
              arcLabelsSkipAngle={15}
              isInteractive={false}
              animate={false}
              valueFormat={(value) => `${Math.round(value)}%`}
              layers={[
                'arcs',
                'arcLabels',
                'legends',
                CenteredMetric,
              ]}
              theme={{
                background: '#fbfaf8',
                text: {
                  fontFamily: FONT_FAMILY,
                  fontSize: downloadVersion ? 10 : 14,
                },
                legends: {
                  text: {
                    fontSize: downloadVersion ? 8 : 12,
                    fill: '#011E26',
                  },
                },
              }}
              height={downloadVersion ? 400 : 800}
              width={downloadVersion ? 400 : 500}
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: false,
                  translateX: 0,
                  translateY: downloadVersion ? 160 : 190,
                  itemsSpacing: downloadVersion? 5 : 10,
                  itemWidth: 70,
                  itemHeight: downloadVersion? 12 : 25,
                  itemTextColor: '#011e26',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: downloadVersion? 10 : 18,
                  symbolShape: 'square',
                  data: data.filter((_, index) => index % 2 === 0).map((value) => ({
                    id: value.id,
                    label: `${value.label} (${numberLocaleFormatter(value.value)}%)`,
                    color: value.color
                  }))
                },
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: -80,
                  translateY: downloadVersion ? 160 : 190,
                  itemsSpacing: downloadVersion? 5 : 10,
                  itemWidth: 70,
                  itemHeight: downloadVersion? 12 : 25,
                  itemTextColor: '#011e26',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: downloadVersion? 10 : 18,
                  symbolShape: 'square',
                  data: data.filter((_, index) => index % 2 === 1).map((value) => ({
                    id: value.id,
                    label: `${value.label} (${numberLocaleFormatter(value.value)}%)`,
                    color: value.color
                  }))
                }
              ]}
            />
          </ReportChart>
        </ReportDiv>

        <ReportDiv
          className="items-side"
          style={{ ...styles.side, ...styles.itemSide }}
          downloadVersion={downloadVersion}
        >
          <ReportDiv
            id="scope-stats"
            className="stats-container"
            style={styles.scopeStatsContainer}
            downloadVersion={downloadVersion}
          >
            <Stat
              id="scope-1"
              style={styles.scope1}
              label="Scope 1"
              value={`${numberLocaleFormatter(scope1Percent)}%`}
              secondaryValue={`${numberLocaleFormatter(scope1tCO2e)} tCO2e`}
              downloadVersion={downloadVersion}
            />
            <Stat
              id="scope-2"
              style={styles.scope2}
              label="Scope 2"
              value={`${numberLocaleFormatter(scope2Percent)}%`}
              secondaryValue={`${numberLocaleFormatter(scope2tCO2e)} tCO2e`}
              downloadVersion={downloadVersion}
            />
            <Stat
              id="scope-3"
              style={styles.scope3}
              label="Scope 3"
              value={`${numberLocaleFormatter(scope3Percent)}%`}
              secondaryValue={`${numberLocaleFormatter(scope3tCO2e)} tCO2e`}
              downloadVersion={downloadVersion}
            />
          </ReportDiv>
          {details[0] ? (
            <ReportEditor
              contentId={details[0].id}
              editorContent={details[0].comments}
              downloadVersion={downloadVersion}
              onChange={() => onContentUpdate()}
              editable={editable}
            />
          ) : (
            !downloadVersion && editable && (
              <ReportEditor
                pageId={page.pageId}
                editorContent={{
                  boxAccent: true,
                  boxAccentColor: 'dark',
                  header: 'Key Insights',
                  content: [],
                  type: 'text',
                }}
                onChange={() => onContentUpdate()}
                newEditor
              />
            )
          )}

          <ReportDiv
            className="stats-container"
            style={styles.statsContainer}
            downloadVersion={downloadVersion}
          >
            <ReportDiv
              id="commuting"
              className="icon-text"
              style={styles.iconText}
              downloadVersion={downloadVersion}
            >
              <ReportDiv
                className="icon-container travel"
                style={{
                  ...styles.iconContainer,
                  ...styles.iconContainerTravel,
                }}
                downloadVersion={downloadVersion}
              >
                <ReportImage
                  alt="Car"
                  aria-label="Car"
                  className="icon car"
                  style={styles.icon}
                  src={CarIcon}
                  downloadVersion={downloadVersion}
                />
              </ReportDiv>
              <ReportP
                style={styles.iconTextP}
                downloadVersion={downloadVersion}
              >
                {numberLocaleFormatter(extras.totaltCo2e)} tCO2e is the equivalent
                of{' '}
                <ReportB downloadVersion={downloadVersion}>
                  driving around the world{' '}
                  {numberLocaleFormatter(extras.totalEquivalentDriving)} times
                </ReportB>{' '}
                in an average-sized petrol car
              </ReportP>
            </ReportDiv>
            <ReportDiv
              id="business-travel"
              className="icon-text"
              style={styles.iconText}
              downloadVersion={downloadVersion}
            >
              <ReportDiv
                className="icon-container travel"
                style={{
                  ...styles.iconContainer,
                  ...styles.iconContainerTravel,
                }}
                downloadVersion={downloadVersion}
              >
                <ReportImage
                  alt="Airplane"
                  aria-label="Airplane"
                  className="icon plane"
                  style={styles.icon}
                  src={PlaneIcon}
                  downloadVersion={downloadVersion}
                />
              </ReportDiv>
              <ReportP
                style={styles.iconTextP}
                downloadVersion={downloadVersion}
              >
                {numberLocaleFormatter(extras.totaltCo2e)} tCO2e is the equivalent
                of{' '}
                <ReportB downloadVersion={downloadVersion}>
                  {numberLocaleFormatter(extras.totalEquivalentFlying)} return flights
                </ReportB>{' '}
                between the UK and Hong Kong
              </ReportP>
            </ReportDiv>
            <ReportDiv
              id="per-fte"
              className="icon-text"
              style={styles.iconText}
              downloadVersion={downloadVersion}
            >
              <ReportDiv
                className="icon-container"
                style={styles.iconContainer}
                downloadVersion={downloadVersion}
              >
                <ReportImage
                  alt="Person"
                  aria-label="Person"
                  className="icon person"
                  style={styles.icon}
                  src={PersonIcon}
                  downloadVersion={downloadVersion}
                />
              </ReportDiv>
              <ReportP
                style={styles.iconTextP}
                downloadVersion={downloadVersion}
              >
                {numberLocaleFormatter(extras.totaltCo2e)} tCO2e is the equivalent
                of{' '}
                <ReportB downloadVersion={downloadVersion}>
                  {numberLocaleFormatter(extras.tCO2ePerFTE, 1)} tCO2e per FTE.
                </ReportB>
              </ReportP>
            </ReportDiv>
          </ReportDiv>
        </ReportDiv>
      </ReportDiv>
    </ReportPage>
  );
};
